<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12">
      <!-- FILTRO -->
      <v-form @submit.prevent="buscar()">
        <v-row>
          <v-col cols="6" md="4">
            Serie
            <v-text-field
              v-model.trim="serie"
              hide-details
              autofocus
              clearable
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-btn
            class="mt-8 ml-4"
            type="submit"
            color="info"
          >
            <v-icon left>fas fa-search</v-icon>
            Buscar
          </v-btn>
          <v-btn
            v-if="permiso"
            class="mt-sm-8 ml-sm-6 ml-3"
            color="success"
            :disabled="!boton"
            @click="dialog = true"
          >
            <v-icon left>fas fa-exchange-alt</v-icon>
            Modificar serie
          </v-btn>
        </v-row>
      </v-form>
      <!-- CABECERA -->
      <v-card class="mt-8 mb-4">
        <v-card-text>
          <v-row class="body-1">
            <v-col cols="12" sm="3" class="py-1"><strong>Código:</strong> {{ articulo.codigo }}</v-col>
            <v-col cols="12" sm="9" class="py-1"><strong>Artículo:</strong> {{ articulo.nombre }}</v-col>
            <v-col cols="12" sm="3" class="py-1"><strong>Estado:</strong> {{ articulo.estado }}</v-col>
            <v-col cols="12" sm="9" class="py-1"><strong>Serie:</strong> {{ articulo.serie }}</v-col>
            <v-col cols="12" sm="12" class="d-flex justify-end align-center py-1">
              <strong>
                <v-chip v-if="historico.length > 0" :color="articulo.en_stock ? 'green' : 'error'">
                  <span class="white--text body-1 font-weight-medium">{{ articulo.detalle }}</span>
                </v-chip>
                <span v-if="articulo.en_stock == 1"> - {{ articulo.bodega }}</span>
              </strong>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <!-- DETALLE HISTORICOS -->
      <v-data-table
        class="cebra elevation-2 mt-2"
        :headers="headers"
        :items="historico"
        :item-class="itemRowBackground"
        :items-per-page="-1"
        hide-default-footer
        dense
      >
        <template v-slot:top>
          <div class="pt-2 ml-2">
            Histórico
          </div>
        </template>
        <template v-slot:[`item.acciones`]="{ item }">
          <v-tooltip bottom v-if="item.movimiento == 'VENTA'">
            <template v-slot:activator="{ on }">
              <v-btn small icon color="success" @click="descargarFactura(item)" v-on="on">
                <v-icon small>fas fa-print</v-icon>
              </v-btn>
            </template>
            <span>Factura</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-col>
    <!-- COMPONENTES -->
    <ModificarSerie
      v-model="dialog"
      :serie="serie"
      :articulo_nombre="articulo.nombre"
      @actualizar="cambiar_serie"
    />
  </v-row>
</template>

<script>
import BtnFiltro from '../../components/util/BtnFiltro'
import ModificarSerie from '../../components/generales/ModificarSerie'

export default {
  data () {
    return {
      permiso: false,
      dialog: false,
      boton: false,
      serie: '',
      headers: [
        { text: 'Fecha', value: 'fecha', sortable: false, filterable: false },
        { text: 'Movimiento', value: 'movimiento', sortable: false, filterable: false },
        { text: 'Número', value: 'numero', sortable: false, filterable: false },
        { text: 'Estado', value: 'estado', sortable: false, filterable: false },
        { text: 'Bodega origen', value: 'origen', sortable: false, filterable: false },
        { text: 'Bodega destino', value: 'destino', sortable: false, filterable: false },
        { text: 'Acciones', value: 'acciones', sortable: false, filterable: false }
      ],
      historico: [],
      articulo: {}
    }
  },
  async created () {
    this.$store.state.loading = true
    this.permiso = await this.$store.dispatch('genericosAcc/permniso_modif_serie')
    this.$store.state.loading = false
  },
  components: {
    BtnFiltro,
    ModificarSerie
  },
  watch: {
    serie () {
      this.boton = false
      this.articulo = {}
      this.historico = []
    }
  },
  methods: {
    cambiar_serie (nueva) {
      this.serie = nueva
      this.buscar()
    },
    async buscar () {
      if (!this.serie) {
        return this.$store.dispatch('show_snackbar', {
          text: 'Debe ingresar una serie',
          color: 'orange'
        })
      }
      this.$store.state.loading = true
      this.articulo = {}
      this.historico = []
      await this.$store.dispatch('genericosAcc/historico_series', this.serie)
        .then((res) => {
          this.articulo = res.articulo
          this.boton = this.articulo.en_stock == 1
          this.historico = res.historico
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    itemRowBackground (item) {
      return item.estado == null ? '' : item.estado.indexOf('PENDIENTE') >= 0 ? 'background-color: rowwar' : ''
    },
    async descargarFactura(item){
      this.$store.state.loading = true
      let idPeticion = await this.$store.dispatch('genericosAcc/get_comprobante_id', {numero: item.numero})
      this.$store.state.loading = false
      if (idPeticion.resultado == 1){
        let link = ''
        // mayorista o de salon?
        if (idPeticion.tipo_comprobante == 58){
          // salon
          link = `http://200.45.250.4:8800/bb_web/public/comprobantepdf/${idPeticion.comprobante_id}`
        }else{
          // mayorista
          link = `http://200.45.250.4:8800/bb_web/public/comprobantepdfMayorista/${idPeticion.comprobante_id}`
        }
        window.open(link, '_blank')
      }else{
        this.$store.dispatch('show_snackbar', {
          text: idPeticion.msj,
          color: 'error'
        })
      }
    }
  }
}
</script>